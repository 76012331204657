<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="权益名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入权益名称"></a-input>
					</a-form-item>

					<!-- <a-form-item label="会员类型" name="memberType" class="ui-form__item">
						<a-select v-model:value="formState.memberType" placeholder="请选择会员类型" allow-clear style="width: 200px;">
							<a-select-option :value="1">普通</a-select-option>
							<a-select-option :value="2">特色</a-select-option>
						</a-select>
					</a-form-item> -->
					
					<a-form-item label="权益类型" name="rightType" class="ui-form__item">
						<a-select v-model:value="formState.rightType" placeholder="请选择权益类型" allow-clear style="width: 200px;">
							<a-select-option :value="1">赠送优惠券</a-select-option>
							<a-select-option :value="2">赠送积分</a-select-option>
							<a-select-option :value="3">赠送免费退改签次数</a-select-option>
							<a-select-option :value="4">生日加倍成长值</a-select-option>
							<!-- <a-select-option :value="5">活动资格</a-select-option> -->
							<a-select-option :value="6">赠送一卡通</a-select-option>
							<a-select-option :value="7">赠送商品</a-select-option>
							<a-select-option :value="8">会员升级加速</a-select-option>
							<a-select-option :value="9">会员专属座位</a-select-option>
							<a-select-option :value="10">优惠购票</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择权益状态" allow-clear style="width: 200px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<a-button v-permission="['user_member_equity_add']" type="primary" @click="onAdd">新增权益</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'rightType'">
							<div>{{ ['赠送优惠券', '赠送积分', '赠送免费退改签次数', '生日加倍成长值', '活动资格', '赠送一卡通', '赠送商品', '会员升级加速', '会员专属座位','优惠购票'][record.rightType - 1] }}</div>
						</template>
						<template v-if="column.key === 'memberType'">
							{{ ['普通会员', '特色会员'][record.memberType - 1] }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							<div>{{ record.isDisabled ? '禁用' : '启用' }}</div>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div>
											<a-menu-item @click="onSee(record)">
												查看
											</a-menu-item>
										</div>
										<div v-permission="['user_member_equity_edit']">
											<a-menu-item @click="onEdit(record)">
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['user_member_equity_disabled']" v-if="record.isDisabled"
											@click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['user_member_equity_disabled']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<temp v-if="showModal" :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getMemberRightList,
		disabledMemberRight
	} from "@/service/modules/member";
	export default {
		components: {
			Icon,
			temp,
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '权益名称',
					dataIndex: 'name',
				}, {
					title: '权益类型',
					key: 'rightType',
				},
				// {
				// 	title: '会员类型',
				// 	key: 'memberType'
				// }, 
				{
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberRightList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.getData();
				}
			},
			onAdd() {
				this.id = 0;
				this.isSee = false;
				this.isEdit = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isSee = false;
				this.isEdit = true;
				this.showModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isSee = true;
				this.isEdit = false;
				this.showModal = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该权益吗',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await disabledMemberRight({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功！');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>